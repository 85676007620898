import React, { useEffect } from "react";
import { Container, Typography, Paper, Divider } from "@mui/material";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import ReactGA from "react-ga4";

const PrivacyPolicy = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/privacy",
      title: "Privacy Policy",
    });
  }, []);

  return (
    <Container>
      <Header />

      <div style={{ marginTop: "100px" }}>
        <Typography
          variant="h2"
          gutterBottom
          style={{ color: "#333" }}
        ></Typography>
        <Paper
          elevation={3}
          sx={{
            padding: "20px",
            marginTop: "20px",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Typography variant="h5" style={{ color: "#333" }}>
            {" "}
            Privacy Policy
          </Typography>
          <Divider sx={{ marginY: "10px" }} />
          <Typography paragraph style={{ color: "#777" }}>
            Last updated: 15-11-2023
          </Typography>
          <Typography paragraph style={{ color: "#777" }}>
            Thank you for choosing Positune.in, your positive mindset trainer,
            and audiobook summaries provider. This Privacy Policy outlines how
            we collect, use, disclose, and safeguard your personal information.
          </Typography>
          <Typography variant="h6" style={{ color: "#555" }}>
            Information We Collect
          </Typography>
          <Typography paragraph style={{ color: "#777" }}>
            a. Email Address: We collect your email address for the purpose of
            providing you with updates, newsletters, and information related to
            positive mindset training and audiobook summaries.
          </Typography>
          <Typography paragraph style={{ color: "#777" }}>
            b. Optional Information: Name (optional) - We may collect your name
            to personalize our communications with you.
          </Typography>
          <Typography variant="h6" style={{ color: "#555" }}>
            How We Use Your Information
          </Typography>
          <Typography paragraph style={{ color: "#777" }}>
            We use the collected information for sending updates, newsletters,
            and information related to positive mindset training and audiobook
            summaries.
          </Typography>
          <Typography variant="h6" style={{ color: "#555" }}>
            Cookies and Similar Technologies
          </Typography>
          <Typography paragraph style={{ color: "#777" }}>
            We do not use cookies or similar technologies for tracking or
            analyzing user behavior.
          </Typography>
          <Typography variant="h6" style={{ color: "#555" }}>
            Third-Party Links
          </Typography>
          <Typography paragraph style={{ color: "#777" }}>
            Our website may contain links to third-party websites. We have no
            control over the content, privacy policies, or practices of these
            websites and encourage you to review their privacy policies.
          </Typography>
          <Typography variant="h6" style={{ color: "#555" }}>
            Security
          </Typography>
          <Typography paragraph style={{ color: "#777" }}>
            We prioritize the security of your personal information and follow
            industry best practices to protect it.
          </Typography>
          <Typography variant="h6" style={{ color: "#555" }}>
            Your Choices
          </Typography>
          <Typography paragraph style={{ color: "#777" }}>
            You can opt out of receiving emails or newsletters by following the
            instructions in the email. You may also contact us to update or
            delete your information.
          </Typography>
          <Typography variant="h6" style={{ color: "#555" }}>
            Changes to This Privacy Policy
          </Typography>
          <Typography paragraph style={{ color: "#777" }}>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </Typography>
          <Typography variant="h6" style={{ color: "#555" }}>
            Contact Us
          </Typography>
          <Typography paragraph style={{ color: "#777" }}>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at <a href="mailto:support@positune.in">support@positune.in</a>.
          </Typography>
        </Paper>
      </div>
      <Footer />
    </Container>
  );
};

export default PrivacyPolicy;
