import React from "react";
import "./footer.css";
function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="text-center py-4 text-gray-500 text-sm">
        © {currentYear}
        <a href="https://positune.in" className="mx-1">
          Positune.
        </a>
        All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
