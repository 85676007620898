import React from "react";
import ReactGA from "react-ga4";
function Newsletter() {
  ReactGA.send({
    hitType: "pageview",
    page: "/newsletter",
    title: "Newsletter",
  });
  return (
    <div>
      <h1>Newsletter</h1>
      <p>How can we help you today?</p>
    </div>
  );
}
export default Newsletter;
