import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/home";
import Support from "./pages/support";
import About from "./pages/about";
import Cancellation from "./pages/cancellation";
import Newsletter from "./pages/newsletter";
import Offers from "./pages/offers";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import PosituneImage from "./assets/positune.png";
import ReactGA from "react-ga4";

ReactGA.initialize("G-DS0K80W5HH");

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/cancellation" element={<Cancellation />} />
      <Route path="/refund" element={<Cancellation />} />
      <Route path="/newsletter" element={<Newsletter />} />
      <Route path="/offers" element={<Offers />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/support" element={<Support />} />
      <Route path="/terms" element={<Terms />} />
      <Route
        path="/positune.png"
        element={
          <img
            src={PosituneImage}
            alt="Positune"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        }
      />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
