import React from "react";
import ReactGA from "react-ga4";
import { Container, Typography, Divider } from "@mui/material";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

function Terms() {
  ReactGA.send({
    hitType: "pageview",
    page: "/terms",
    title: "Terms of Service",
  });

  return (
    <Container>
      <Header />

      <section style={{ marginTop: "100px" }}>
        <Typography variant="h4">Support Page</Typography>
        <Typography>
          Welcome to the PosiTune Support Center! We are dedicated to providing
          you with the assistance you need for a positive and enriching
          experience on our platform.
        </Typography>

        <Divider />

        <section>
          <Typography variant="h5">
            Frequently Asked Questions (FAQs)
          </Typography>
          <Typography>
            Check out our FAQs for quick answers to common queries related to
            PosiTune.in, positive mindset training, and audiobook summaries.
            positune.in/faq
          </Typography>
        </section>

        <section>
          <Typography variant="h5">Getting Started</Typography>
          <Typography>
            If you're new to PosiTune.in, our Getting Started guide will walk
            you through the basics, helping you make the most of our positive
            mindset training and audiobook summaries. positune.in/app
          </Typography>
        </section>

        <section>
          <Typography variant="h5">Account Management</Typography>
          <Typography>
            Learn how to manage your PosiTune.in account, update your profile,
            and handle any account-related issues. positune.in/account
          </Typography>
        </section>

        <section>
          <Typography variant="h5">Positive Mindset Training</Typography>
          <Typography>
            Dive deeper into our positive mindset training programs. Find tips,
            techniques, and resources to enhance your journey towards a more
            positive mindset. positune.in/app
          </Typography>
        </section>

        <section>
          <Typography variant="h5">Audio Book Summaries</Typography>
          <Typography>
            Explore our collection of audiobook summaries. Discover how to make
            the most of this feature and find summaries tailored to your
            preferences. positune.in/app
          </Typography>
        </section>

        <section>
          <Typography variant="h5">Contact Support</Typography>
          <Typography>
            If you couldn't find the answers you were looking for or if you have
            specific questions, feel free to reach out to our support team.
            contact@positune.in
          </Typography>
        </section>

        <section>
          <Typography variant="h5">Feedback and Suggestions</Typography>
          <Typography>
            We value your feedback! Share your thoughts, suggestions, or ideas
            to help us improve your PosiTune experience. support@positune.in
          </Typography>
        </section>

        <section>
          <Typography variant="h5">Privacy Concerns</Typography>
          <Typography>
            If you have privacy concerns or questions about how we handle your
            personal information, please review our Privacy Policy or contact
            our privacy team. positune.in/privacy
          </Typography>
        </section>

        <Typography>
          Thank you for choosing PosiTune.in. We're here to support you on your
          journey to a positive mindset!
        </Typography>
      </section>

      <Footer />
    </Container>
  );
}

export default Terms;
