import React, { useState } from "react";

import ReactGA from "react-ga4";
// import "../styles/offers.css";

function Offers() {
  ReactGA.send({
    hitType: "pageview",
    page: "/offers",
    title: "Offers",
  });

  const [toggle, setToggle] = useState(false);

  const togglePrices = () => {
    setToggle(!toggle);
  };
  const styles = `
  
 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } 
  
:root {
    --white: #fff;
    --purple1: #f5f1ff;
    --purple2: #6702fa;
    --purple3: #5902d8;
    --blue: #283fa7;
    --yellow: #f9e16c;
    --green: #00cc39;
    --gray1: #ebebeb;
    --gray2: #c4c4c4;
    --gray3: #767679;
    --black: #262626;
  } 
  
 html {
    font-size: 62.5%;
  } 
  
body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: var(--purple1);
    font-size: 1.6rem;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
  }
  
  a {
    text-decoration: none;
  }
  
  ul {
    list-style: none;
  }
  

  .title {
    font-size: 4rem;
    color: var(--purple2);
    padding-top: 7rem;
  }
  

  .toggle {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
  
  /* Monthly and annually text next to toggle switch */
  .toggle__period {
    font-size: 1.5rem;
    color: var(--black);
    letter-spacing: 0.5px;
  }
  
  /* Checkbox */
  .toggle__checkbox {
    width: 0;
    height: 0;
    visibility: hidden;
  }
  
  /* Switch background */
  .toggle__background {
    width: 4.9rem;
    height: 2.6rem;
    background: var(--gray2);
    border-radius: 100px;
    position: relative;
    cursor: pointer;
    margin: 0 1.2rem;
  }
  
  /* Circle within toggle switch */
  .toggle__background::after {
    content: "";
    width: 2.2rem;
    height: 2.2rem;
    background: #fff;
    position: absolute;
    left: 4px;
    top: 2px;
    border-radius: 50%;
    transition: all 0.5s ease;
  }
  
  /* Change switch container background when toggle switch is clicked */
  .toggle__checkbox:checked + .toggle__background {
    /* background: var(--purple2); */
  }
  
  /* Move circle when toggle switch is clicked */
  .toggle__checkbox:checked + .toggle__background:after {
    left: calc(100% - 26px);
  }
  
  /*********
     * CARDS * 
     *********/
  
  .cards {
    padding-bottom: 7rem;
  }
  
  /* Styles for every card */
  .card {
    width: 90%;
    max-width: 40rem;
    margin: 0 auto;
    padding: 4rem 2rem;
    background: var(--white);
    border-radius: 0.5rem;
    box-shadow: 0 0 5px var(--gray1);
  }
  
  /* Second card */
  .card--multisite {
    position: relative;
    margin: 2rem auto;
  }
  
  .card__badge {
    background: var(--yellow);
    color: var(--blue);
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem 1rem 0.5rem 1.6rem;
    border-bottom-left-radius: 40px;
    font-family: "Open Sans", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }
  
  .card__plan,
  .card__price,
  .card__period {
    /* color: var(--purple2); */
  }
  
  .card__plan {
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 1px;
  }
  
  .card__price {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  
  .card__price::after {
    content: "/ month";
    position: relative;
    left: 5px;
    font-weight: 400;
    font-size: 1.5rem;
  }
  
  .card__period {
    position: relative;
    left: -5px;
    font-weight: 400;
    font-size: 1.5rem;
  }
  
  .card__description,
  .card__feature {
    color: var(--gray3);
  }
  
  .card__description {
    font-size: 1.5rem;
    letter-spacing: 0.5px;
    line-height: 2.2rem;
  }
  
  /* Dividing line below description */
  .card__description::after {
    content: "";
    height: 1.5px;
    width: 100%;
    display: block;
    background: var(--gray1);
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  
  .card__feature {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
  }
  
  /* Checkmark icons */
  .feature__icon {
    width: 1.8rem;
    margin-right: 0.65rem;
    color: var(--green);
  }
  
  /* Feature text next to checkmark */
  .feature__text {
    letter-spacing: 0.5px;
  }
  
  .card__btn {
    width: 100%;
    background: var(--purple2);
    color: var(--white);
    margin-top: 2rem;
    padding: 1.4rem 0;
    border: none;
    border-radius: 5px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.7rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  /***************
   * BASE STYLES * 
   ***************/
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  :root {
    --white: #fff;
    --purple1: #f5f1ff;
    --purple2: #6702fa;
    --purple3: #5902d8;
    --blue: #283fa7;
    --yellow: #f9e16c;
    --green: #00cc39;
    --gray1: #ebebeb;
    --gray2: #c4c4c4;
    --gray3: #767679;
    --black: #262626;
  }
  
  html {
    font-size: 62.5%;
  }
  
  body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: var(--purple1);
    font-size: 1.6rem;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
  }
  
  a {
    text-decoration: none;
  }
  
  ul {
    list-style: none;
  }
  
  /* Title */
  .title {
    font-size: 4rem;
    color: var(--purple2);
    padding-top: 7rem;
  }
  
  /*****************
   * TOGGLE SWITCH * 
   *****************/
  
  /* Toggle switch */
  .toggle {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Monthly and annually text next to toggle switch */
  .toggle__period {
    font-size: 1.5rem;
    color: var(--black);
    letter-spacing: 0.5px;
  }
  
  /* Checkbox */
  .toggle__checkbox {
    width: 0;
    height: 0;
    visibility: hidden;
  }
  
  /* Switch background */
  .toggle__background {
    width: 4.9rem;
    height: 2.6rem;
    background: var(--gray2);
    border-radius: 100px;
    position: relative;
    cursor: pointer;
    margin: 0 1.2rem;
  }
  
  /* Circle within toggle switch */
  .toggle__background::after {
    content: "";
    width: 2.2rem;
    height: 2.2rem;
    background: #fff;
    position: absolute;
    left: 4px;
    top: 2px;
    border-radius: 50%;
    transition: all 0.5s ease;
  }
  
  /* Change switch container background when toggle switch is clicked */
  .toggle__checkbox:checked + .toggle__background {
    background: var(--purple2);
  }
  
  /* Move circle when toggle switch is clicked */
  .toggle__checkbox:checked + .toggle__background:after {
    left: calc(100% - 26px);
  }
  
  /*********
   * CARDS * 
   *********/
  
  .cards {
    padding-bottom: 7rem;
  }
  
  /* Styles for every card */
  .card {
    width: 90%;
    max-width: 40rem;
    margin: 0 auto;
    padding: 4rem 2rem;
    background: var(--white);
    border-radius: 0.5rem;
    box-shadow: 0 0 5px var(--gray1);
  }
  
  /* Second card */
  .card--multisite {
    position: relative;
    margin: 2rem auto;
  }
  
  .card__badge {
    background: var(--yellow);
    color: var(--blue);
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem 1rem 0.5rem 1.6rem;
    border-bottom-left-radius: 40px;
    font-family: "Open Sans", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }
  
  .card__plan,
  .card__price,
  .card__period {
    color: var(--purple2);
  }
  
  .card__plan {
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 1px;
  }
  
  .card__price {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  
  .card__price::after {
    content: "/ month";
    position: relative;
    left: 5px;
    font-weight: 400;
    font-size: 1.5rem;
  }
  
  .card__period {
    position: relative;
    left: -5px;
    font-weight: 400;
    font-size: 1.5rem;
  }
  
  .card__description,
  .card__feature {
    color: var(--gray3);
  }
  
  .card__description {
    font-size: 1.5rem;
    letter-spacing: 0.5px;
    line-height: 2.2rem;
  }
  
  /* Dividing line below description */
  .card__description::after {
    content: "";
    height: 1.5px;
    width: 100%;
    display: block;
    background: var(--gray1);
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  
  .card__feature {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
  }
  
  /* Checkmark icons */
  .feature__icon {
    width: 1.8rem;
    margin-right: 0.65rem;
    color: var(--green);
  }
  
  /* Feature text next to checkmark */
  .feature__text {
    letter-spacing: 0.5px;
  }
  
  .card__btn {
    width: 100%;
    background: var(--purple2);
    color: var(--white);
    margin-top: 2rem;
    padding: 1.4rem 0;
    border: none;
    border-radius: 5px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.7rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  @media screen and (min-width: 1025px) {
    .cards {
      display: flex;
      justify-content: center;
      width: 97%;
    }
  
    .card {
      width: auto;
      margin: 0;
    }
  
    /* Second card */
    .card--multisite {
      margin: 0 1rem;
    }
  }
  
  /* These styles will be applied to devices with a width of at least 1270px */
  @media screen and (min-width: 1270px) {
    .title {
      font-size: 5rem;
    }
  
    /* Toggle switch container */
    .toggle {
      margin-top: 3rem;
      margin-bottom: 4rem;
      
    }
  
    /* Annually and monthly texts */
    .toggle__period {
      font-size: 1.6rem;
    }
  
    .cards {
      width: 100%;
    }
  
    /* Second card */
    .card--multisite {
      margin: 0 2rem;
    }
  }
  
  /* If the device supports hover, we change the button background color */
  @media (hover: hover) {
    .card__btn:hover {
      background: var(--purple3);
    }
  }
  `;
  return (
    <div className="">
      <style>{styles}</style>
      <div style={{ textAlign: "center" }}>
        <h1 className="title">Our Plans</h1>
        <div className="toggle ">
          <span className="toggle__period toggle__period--annually">
            Annually
          </span>
          <input
            type="checkbox"
            id="switch"
            className="toggle__checkbox"
            onChange={togglePrices}
          />
          <label className="toggle__background" htmlFor="switch"></label>
          <span className="toggle__period toggle__period--monthly">
            Monthly
          </span>
        </div>
      </div>

      <div className="cards">
        <div className="card card--basic">
          <h3 className="card__plan">Basic</h3>
          <h2 className="card__price">{toggle ? "$5.99" : "$3.99"}</h2>

          <p className="card__description">
            An economical, 10 GB plan with free matching domain and free email.
          </p>
          <ul>
            <li className="card__feature feature">
              <i data-feather="check" className="feature__icon"></i>
              <span className="feature__text">1 Website</span>
            </li>
            <li className="card__feature feature">
              <i data-feather="check" className="feature__icon"></i>
              <span className="feature__text">10 GB Web Space</span>
            </li>
            <li className="card__feature feature">
              <i data-feather="check" className="feature__icon"></i>
              <span className="feature__text">~ 10,000 Visits Monthly</span>
            </li>
            <li className="card__feature feature">
              <i data-feather="check" className="feature__icon"></i>
              <span className="feature__text">Unmetered Traffic</span>
            </li>
          </ul>
          <button className="card__btn">Get Started</button>
        </div>

        <div className="card card--multisite">
          <span className="card__badge">popular</span>
          <h3 className="card__plan">Multisite</h3>
          <h2 className="card__price">{toggle ? "$12.99" : "$6.99"}</h2>

          <p className="card__description">
            Unlimited sites with 20 GB storage. Free matching domain and free
            email.
          </p>
          <ul>
            <li className="card__feature feature">
              <i data-feather="check" className="feature__icon"></i>
              <span className="feature__text">Unlimited Websites</span>
            </li>
            <li className="card__feature feature">
              <i data-feather="check" className="feature__icon"></i>
              <span className="feature__text">20 GB Web Space</span>
            </li>
            <li className="card__feature feature">
              <i data-feather="check" className="feature__icon"></i>
              <span className="feature__text">~ 100,000 Visits Monthly</span>
            </li>
            <li className="card__feature feature">
              <i data-feather="check" className="feature__icon"></i>
              <span className="feature__text">Unmetered Traffic</span>
            </li>
          </ul>
          <button className="card__btn">Get Started</button>
        </div>

        <div className="card card--business">
          <h3 className="card__plan">Business</h3>
          <h2 className="card__price">{toggle ? "$19.99" : "$9.99"}</h2>
          <p className="card__description">
            Increased processing power with unlimited sites, storage and
            databases.
          </p>
          <ul>
            <li className="card__feature feature">
              <i data-feather="check" className="feature__icon"></i>
              <span className="feature__text">Unlimited Websites</span>
            </li>

            <li className="card__feature feature">
              <i data-feather="check" className="feature__icon"></i>
              <span className="feature__text">Unlimited Web Space</span>
            </li>

            <li className="card__feature feature">
              <i data-feather="check" className="feature__icon"></i>
              <span className="feature__text">~ 400,000 Visits Monthly</span>
            </li>

            <li className="card__feature feature">
              <i data-feather="check" className="feature__icon"></i>
              <span className="feature__text">Unmetered Traffic</span>
            </li>
          </ul>
          <button className="card__btn">Get Started</button>
        </div>
      </div>
    </div>
  );
}

export default Offers;
