import image from "../../assets/img4.png";
import { HashLink } from "react-router-hash-link";

export default function Main() {
  return (
    <div
      id="main"
      className="flex justify-around w-[100%] py-32 gap-8 items-center tablet:flex-col"
    >
      <div className="w-[50%] mobile:w-[100%] tablet:w-[100%] tablet:text-center">
        <h1 className="font-[700] h-full text-5xl leading-snug animate-headline">
          {" "}
        </h1>
        <p className="text-base mt-5 w-[70%] mobile:w-[100%] tablet:w-[100%]">
          Stay tuned for the launch of our affirmations app, designed to
          transform your life into a more positive and fulfilling experience.
        </p>
        <div className="tablet:flex justify-center items-center">
          <div className="rounded-2xl mt-5 mb-5 bg-[#009379] w-[200px] tablet:w-[50%] text-center p-4 text-l font-bold text-[#fff]">
            <HashLink smooth to={"#get-started"}>
              Get Started
            </HashLink>
          </div>
        </div>
      </div>
      <img
        src={image}
        className="w-[35%] mobile:w-[100%] justify-self-end tablet:w-[100%]"
        alt="Hero"
      />
    </div>
  );
}
