import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useState, useEffect } from "react";
import Select from "react-select";
import Slider from "@mui/material/Slider";
import Swal from "sweetalert2";
import { InputAdornment } from "@mui/material";
import { AccountCircle, Email } from "@mui/icons-material";
import {
  languageOptions,
  amtINRMonthly,
  amtINRYearly,
  amtUSDMonthly,
  amtUSDYearly,
  valuetextINR,
  valuetextUSD,
  states,
} from "../../data/data";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import image from "../../assets/home.png";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import ReactGA from "react-ga4";
export default function SignUp() {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [languageItems, setLanguageItems] = useState([]);
  const [monthlyPrice, setMonthlyPrice] = useState(40);
  const [yearlyPrice, setYearlyPrice] = useState(20);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
      });
  }, []);

  const findAmount = (list, value) => list.find((key) => key.value === value);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    const formData = event.target;
    const payload = {
      email: formData[0].value,
      name: formData[2].value,
      country: selectedCountry.label,
      state: formData[7].value,
      platforms: [
        `${formData[4].checked && "Android"}`,
        `${formData[5].checked && "Ios"}`,
      ],
      languages: languageItems.map((e) => e.value),
      currency: selectedCountry.value !== "IN" ? "USD" : "INR",
      monthly_price: findAmount(
        selectedCountry.value !== "IN" ? amtUSDMonthly : amtINRMonthly,
        monthlyPrice
      ).amount,
      annual_price: findAmount(
        selectedCountry.value !== "IN" ? amtUSDYearly : amtINRYearly,
        yearlyPrice
      ).amount,
    };
    ReactGA.event({
      category: "Form",
      action: "Form submitted",
      label: "Form submitted",
    });
    const token =
      "DT6rBA1204UFMkZXgluYmd44zQVe7SOmcPx9KaI1q4cldDwJPUhmksrLWbq4g9Zv";
    await axios
      .post(
        "https://positunewebworker-production.up.railway.app/users",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        if (result) {
          Swal.fire({
            icon: "success",
            title: "Woohoo!",
            text: "Form submitted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          // clears the form
          formData.reset();
          // this resets useState values
          setSelectedCountry("");
          setSelectedState("");
          setLanguageItems([]);
          setMonthlyPrice(40);
          setYearlyPrice(20);
        }
      })
      .catch((err) => {
        console.log(err);

        Swal.fire({
          title: "Oops!",
          text: "Something Went Wrong, Try again ;-(",
          icon: "error",
          confirmButtonText: "Okay",
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }
  const formControlStyle = { marginBottom: "2rem" };
  return (
    <div className="px-[5%] signup h-full flex flex-col justify-center">
      <form method="post" onSubmit={handleSubmit} className="h-full">
        <div className="flex w-[100%] items-start gap-20 justify-between tablet:flex-col h-full">
          <div className="w-[50%] tablet:w-[100%]">
            <img
              src={image}
              className="mobile:w-[100%] justify-self-end tablet:w-[100%]"
              alt="Hero"
            />
          </div>
          <div className="w-[50%] tablet:w-[100%]">
            <p className="text-l tablet:text-center mb-8">
              Sign up for our email list to be the first to know when the
              Positune App launches and let us know your preferences.
            </p>
            <FormControl fullWidth size="small" style={formControlStyle}>
              <InputLabel htmlFor="email">Email *</InputLabel>
              <OutlinedInput
                id="email"
                type="email"
                label="Email"
                required
                startAdornment={
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                }
                placeholder="Enter email"
              />
            </FormControl>
            <FormControl size="small" fullWidth style={formControlStyle}>
              <InputLabel htmlFor="name">Name</InputLabel>
              <OutlinedInput
                id="name"
                label="Name"
                placeholder="Enter name"
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormGroup required style={formControlStyle}>
              <InputLabel htmlFor="name">Platform</InputLabel>
              <div>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Android"
                />
                <FormControlLabel control={<Checkbox />} label="Ios" />
              </div>
            </FormGroup>
            <FormControl fullWidth style={formControlStyle}>
              <Select
                options={countries}
                placeholder="Select Country *"
                value={selectedCountry}
                required
                onChange={(selectedOption) =>
                  setSelectedCountry(selectedOption)
                }
              />
            </FormControl>
            {selectedCountry.value === "IN" && (
              <FormControl size="small" fullWidth style={formControlStyle}>
                <Select
                  options={states}
                  placeholder="Select State *"
                  value={selectedState}
                  required
                  onChange={(selectedOption) =>
                    setSelectedState(selectedOption)
                  }
                />
              </FormControl>
            )}
            <FormControl fullWidth style={formControlStyle}>
              <Select
                isMulti
                required
                options={languageOptions}
                placeholder="Choose Languages to hear *"
                value={languageItems}
                onChange={(selectedOption) => setLanguageItems(selectedOption)}
              />
            </FormControl>
            {selectedCountry && (
              <div>
                <FormControl size="small" fullWidth style={formControlStyle}>
                  <div className="px-4">
                    <p htmlFor="currency">Monthly Price *</p>
                    <Slider
                      aria-label=""
                      required
                      defaultValue={40}
                      value={monthlyPrice}
                      onChange={(event) => setMonthlyPrice(event.target.value)}
                      getAriaValueText={
                        selectedCountry.value !== "IN"
                          ? valuetextUSD
                          : valuetextINR
                      }
                      step={20}
                      marks={
                        selectedCountry.value !== "IN"
                          ? amtUSDMonthly
                          : amtINRMonthly
                      }
                    />
                  </div>
                </FormControl>
                <FormControl size="small" fullWidth style={formControlStyle}>
                  <div className="px-4">
                    <p htmlFor="currency">Annual Price *</p>
                    <Slider
                      aria-label=""
                      required
                      defaultValue={40}
                      value={yearlyPrice}
                      onChange={(event) => setYearlyPrice(event.target.value)}
                      getAriaValueText={
                        selectedCountry.value !== "IN"
                          ? valuetextUSD
                          : valuetextINR
                      }
                      step={20}
                      marks={
                        selectedCountry.value !== "IN"
                          ? amtUSDYearly
                          : amtINRYearly
                      }
                    />
                  </div>
                </FormControl>
              </div>
            )}
            <div className="mt-2 mb-10 tablet:text-center">
              {isSubmitting ? (
                <CircularProgress />
              ) : (
                <button
                  type="submit"
                  className="rounded-xl bg-[#009379] px-4 py-2"
                >
                  <h1 className="text-l font-bold text-[#fff]">Submit</h1>
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
