import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Container, Typography, Paper } from "@mui/material";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const Cancellation = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/cancellation",
      title: "Cancellation Policy",
    });
  }, []);

  return (
    <Container>
      <Header />

      <div style={{ marginTop: "100px" }}>
        <Typography
          variant="h4"
          gutterBottom
          style={{ color: "#333", marginBottom: "20px" }}
        >
          Cancellation Policy
        </Typography>

        <Paper
          elevation={3}
          sx={{
            padding: "20px",
            marginTop: "20px",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Typography
            variant="body1"
            style={{
              color: "#333",
              marginBottom: "10px",
              marginLeft: "20px",
            }}
          >
            We're sorry to see you go, but we understand that circumstances may
            change. If you've decided to cancel your subscription or discontinue
            using Positune.in, please review the following information.
          </Typography>

          <Typography
            variant="body1"
            style={{ color: "#333", marginBottom: "10px", marginLeft: "20px" }}
          >
            <strong>Canceling Your Subscription</strong>
          </Typography>

          <Typography
            variant="body1"
            style={{ color: "#333", marginBottom: "10px", marginLeft: "20px" }}
          >
            To cancel your subscription, follow these steps:
          </Typography>

          <ol style={{ marginLeft: "40px", marginBottom: "10px" }}>
            <li>Log in to your Positune.in account.</li>
            <li>
              Navigate to the "Account Settings" or "Subscription" section.
            </li>
            <li>
              Locate the option to "Cancel Subscription" or a similar phrase.
            </li>
            <li>
              Follow the on-screen instructions to complete the cancellation
              process.
            </li>
          </ol>

          <Typography
            variant="body1"
            style={{ color: "#333", marginBottom: "10px", marginLeft: "20px" }}
          >
            <strong>Refund Policy</strong>
          </Typography>

          <Typography
            variant="body1"
            style={{
              color: "#333",
              marginBottom: "10px",
              marginLeft: "20px",
              textIndent: "20px",
            }}
          >
            Please note that Positune.in operates on a 15-day refund policy. If
            you cancel your subscription within this period, you may be eligible
            for a refund. After the refund period expires, you will not be
            eligible for a refund, and your subscription will continue until the
            end of the billing cycle.
          </Typography>

          <Typography
            variant="body1"
            style={{ color: "#333", marginBottom: "10px", marginLeft: "20px" }}
          >
            <strong>Contacting Support</strong>
          </Typography>

          <Typography
            variant="body1"
            style={{
              color: "#333",
              marginBottom: "10px",
              marginLeft: "20px",
              textIndent: "20px",
            }}
          >
            If you encounter any issues or have questions about the cancellation
            process, please don't hesitate to reach out to our support team.
            We're here to assist you. support@positune.in
          </Typography>

          <Typography
            variant="body1"
            style={{ color: "#333", marginBottom: "10px", marginLeft: "20px" }}
          >
            <strong>Feedback</strong>
          </Typography>

          <Typography
            variant="body1"
            style={{
              color: "#333",
              marginBottom: "10px",
              marginLeft: "20px",
              textIndent: "20px",
            }}
          >
            We appreciate your feedback, and if there are specific reasons for
            your cancellation, we would love to hear from you. Your input helps
            us improve our service for the Positune community. Please email us
          </Typography>

          <Typography
            variant="body1"
            style={{ color: "#333", marginBottom: "10px", marginLeft: "20px" }}
          >
            <strong>Reactivating Your Subscription</strong>
          </Typography>

          <Typography
            variant="body1"
            style={{
              color: "#333",
              marginBottom: "10px",
              marginLeft: "20px",
              textIndent: "20px",
            }}
          >
            If you ever decide to return, you can reactivate your subscription
            by logging into your account and following the prompts to
            resubscribe.
          </Typography>

          <Typography
            variant="body1"
            style={{ color: "#333", marginLeft: "20px" }}
          >
            Thank you for being a part of Positune.in. We hope you've found
            value in our positive mindset training and audiobook summaries. If
            there's anything we can do to improve your experience, please let us
            know.
          </Typography>
        </Paper>
      </div>

      <Footer />
    </Container>
  );
};

export default Cancellation;

