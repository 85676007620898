import { HashLink } from "react-router-hash-link";
import logo from "../../assets/logo.svg";
import { useState, useEffect } from "react";

export default function Header() {
  const [isHeaderFixed, setIsHeaderFixed] = useState(true);
  const [prevScrollY, setPrevScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > prevScrollY) {
        setIsHeaderFixed(false);
      } else {
        setIsHeaderFixed(true);
      }
      setPrevScrollY(currentScrollY);
    };

    // Add a scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY]);
  return (
    <header
      className={`py-2 px-[10%] ${
        isHeaderFixed
          ? "fixed top-0 left-0 w-full z-50 bg-white "
          : "bg-white block"
      } transition-all duration-300 ease-in-out`}
    >
      <div className="flex items-center mobile:justify-center justify-between">
        <HashLink smooth to={"/"}>
          <div className="flex items-center gap-1">
            <img
              src={logo}
              width="60px"
              className="shrink-0"
              alt="Positune Logo"
            />
            <h1 className="text-2xl font-bold">
              <span>Posi</span>
              <span className="animate-tune"></span>
            </h1>
          </div>
        </HashLink>

        <div className="mobile:hidden gap-8 flex">
          <HashLink smooth to={"/#contact"} className="px-4 py-2">
            <h1 className="text-l font-bold tablet:hidden text-[#009379]">
              Contact
            </h1>
          </HashLink>
          <HashLink
            smooth
            className="rounded-xl bg-[#E5F4F2] px-4 py-2 tablet:hidden"
            to={"/#features"}
          >
            <h1 className="text-l font-bold text-[#009379]">Features</h1>
          </HashLink>
          <HashLink
            smooth
            to={"/#get-started"}
            className="rounded-xl bg-[#009379] px-4 py-2"
          >
            <h1 className="text-l font-bold text-[#fff]">Get Started</h1>
          </HashLink>
        </div>
      </div>
    </header>
  );
}
