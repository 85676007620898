import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Container, Typography, Paper, Divider } from "@mui/material";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

function Support() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/support",
      title: "Support",
    });
  }, []);

  const sectionStyle = {
    marginTop: "100px",
    marginBottom: "50px",
  };

  const titleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const descriptionStyle = {
    fontSize: "16px",
    marginBottom: "20px",
  };

  const linkStyle = {
    color: "blue",
    textDecoration: "underline",
  };

  return (
    <Container>
      <Header />

      <div style={sectionStyle}>
        <Typography variant="h4" component="h1" gutterBottom style={titleStyle}>
          Support Page
        </Typography>
        <Typography variant="body1" gutterBottom style={descriptionStyle}>
          Welcome to the PosiTune Support Center! We are dedicated to providing
          you with the assistance you need for a positive and enriching
          experience on our platform.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom style={titleStyle}>
          Frequently Asked Questions (FAQs)
        </Typography>
        <Typography variant="body1" gutterBottom style={descriptionStyle}>
          Check out our FAQs for quick answers to common queries related to
          PosiTune.in, positive mindset training, and audiobook summaries.{" "}
          <a href="https://positune.in/faq" style={linkStyle}>
            positune.in/faq
          </a>
        </Typography>
        <Typography variant="body1" gutterBottom style={titleStyle}>
          Getting Started
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you're new to PosiTune.in, our Getting Started guide will walk you
          through the basics, helping you make the most of our positive mindset
          training and audiobook summaries.{" "}
          <a href="https://positune.in/app" style={linkStyle}>
            positune.in/app
          </a>
        </Typography>
        <Typography variant="body1" gutterBottom style={titleStyle}>
          Account Management
        </Typography>
        <Typography variant="body1" gutterBottom>
          Learn how to manage your PosiTune.in account, update your profile, and
          handle any account-related issues.{" "}
          <a href="https://positune.in/account" style={linkStyle}>
            positune.in/account
          </a>
        </Typography>
        <Typography variant="body1" gutterBottom style={titleStyle}>
          Positive Mindset Training
        </Typography>
        <Typography variant="body1" gutterBottom>
          Dive deeper into our positive mindset training programs. Find tips,
          techniques, and resources to enhance your journey towards a more
          positive mindset.{" "}
          <a href="https://positune.in/app" style={linkStyle}>
            positune.in/app
          </a>
        </Typography>
        <Typography variant="body1" gutterBottom style={titleStyle}>
          Audio Book Summaries
        </Typography>
        <Typography variant="body1" gutterBottom>
          Explore our collection of audiobook summaries. Discover how to make
          the most of this feature and find summaries tailored to your
          preferences.{" "}
          <a href="https://positune.in/app" style={linkStyle}>
            positune.in/app
          </a>
        </Typography>
        <Typography variant="body1" gutterBottom style={titleStyle}>
          Contact Support
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you couldn't find the answers you were looking for or if you have
          specific questions, feel free to reach out to our support team. Email
          us at <a href="mailto:contact@positune.in">contact@positune.in</a>.
        </Typography>
        <Typography variant="body1" gutterBottom style={titleStyle}>
          Feedback and Suggestions
        </Typography>
        <Typography variant="body1" gutterBottom>
          We value your feedback! Share your thoughts, suggestions, or ideas to
          help us improve your PosiTune experience. Email us at{" "}
          <a href="mailto:support@positune.in">support@positune.in</a>.
        </Typography>
        <Typography variant="body1" gutterBottom style={titleStyle}>
          Privacy Concerns
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have privacy concerns or questions about how we handle your
          personal information, please review our{" "}
          <a href="https://positune.in/privacy" style={linkStyle}>
            Privacy Policy
          </a>{" "}
          or contact our privacy team.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Thank you for choosing PosiTune.in. We're here to support you on your
          journey to a positive mindset!
        </Typography>
      </div>

      <Footer />
    </Container>
  );
}

export default Support;
