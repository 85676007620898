import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailIcon from "@mui/icons-material/Mail";
import image from "../../assets/img3.png";
import { WhatsApp, YouTube } from "@mui/icons-material";

export default function ContactUs() {
  return (
    <div
      id="contact"
      className="flex flex-col justify-between md:flex-row rounded-lg shadow-gray-300 shadow-lg"
    >
      <div className="w-full md:w-1/2 py-12 px-8">
        <h2 className="text-3xl font-bold">Let's Get in touch</h2>
        <p className="mt-4 text-lg">
          We're always happy to hear from you! Please feel free to contact us
          using any of the methods below.
        </p>
        <div className="mt-8 flex flex-col">
          <div className="flex items-center mt-4">
            <MailIcon className="h-6 w-6 mr-4" />
            <a
              href="mailto:support@positune.in"
              className="text-lg font-medium hover:underline"
            >
              support@positune.in
            </a>
          </div>
        </div>
        <div className="mt-8 flex items-center">
          <a
            href="https://www.whatsapp.com/channel/0029Va8j3tMDJ6H99fzN3T0p"
            className="mr-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsApp className="h-6 w-6" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCg3KBnd6XUgPPyVHHvRuSoQ"
            className="mr-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YouTube className="h-6 w-6" />
          </a>
          <a
            href="https://www.instagram.com/positune.in/"
            className="mr-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon className="h-6 w-6" />
          </a>
          <a
            href="https://www.facebook.com/people/Positune/pfbid034Z1F9P6LyjmdE3V2bXzLM2ycP7zAPXFmbRL1FnSGpVk8emDnzkZuXrAvVyHJNrFXl/"
            className="mr-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon className="h-6 w-6" />
          </a>
        </div>
      </div>
      <div className="w-full md:w-1/2 py-12 px-8">
        <img src={image} alt="Hero" />
      </div>
    </div>
  );
}
