import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

function withUTMTracking(Component) {
  return function WithUTMTrackingComponent(props) {
    const location = useLocation();

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const utmSource = params.get('utm_source');
      const utmMedium = params.get('utm_medium');
      const utmCampaign = params.get('utm_campaign');

      if (utmSource && utmMedium && utmCampaign) {
        ReactGA.event({
          category: 'Campaign',
          action: 'View',
          label: `${utmSource} | ${utmMedium} | ${utmCampaign}`,
        });
      }
    }, [location]);

    return <Component {...props} />;
  };
}

export default withUTMTracking;