import React from "react";
import ReactGA from "react-ga4";
function About() {
  ReactGA.send({
    hitType: "pageview",
    page: "/about",
    title: "About",
  });

  return (
    <div>
      <h1>About</h1>
      <p>How can we help you today?</p>
    </div>
  );
}
export default About;
