import daily from "../../assets/daily.png";
import audio from "../../assets/audio.png";
import reminder from "../../assets/reminder.png";

export default function Feature() {
  return (
    <div className="flex tablet:flex-col gap-10 justify-between h-full">
      <FeatureCard
        title="Daily Positivity Boost"
        image={daily}
        text="Start each day with a personal growth opportunity. Our 'Daily Positivity Boost' sets the tone for positivity."
      />
      <FeatureCard
        title="Sensory Bliss: Audio Affirmations"
        image={audio}
        text="Immerse yourself in uplifting affirmations with 'Sensory Bliss: Audio Affirmations.' Let the power of sound guide you to a more positive life."
      />
      <FeatureCard
        title="Your Unique Path: Personalized Affirmations"
        image={reminder}
        text="Craft a mindset for success with 'Your Unique Path: Personalized Affirmations.' Align your thoughts with your goals for a joyous journey of personal growth."
      />
    </div>
  );
}

function FeatureCard(params) {
  return (
    <div className="text-center py-[4%] w-[28%] tablet:w-[100%] px-[2%] shadow-lg rounded-lg shadow-gray-300 bg-white h-full">
      <div className="flex items-center justify-center">
        <img src={params.image} width="80px" height="80px" alt="Feature" />
      </div>
      <h1 className="text-xl font-[600] mt-8">{params.title}</h1>
      <p className="text-base mt-5 text-justify">{params.text}</p>
    </div>
  );
}
