import ContactUs from "../components/contactus/ContactUs";
import Feature from "../components/features/Feature";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Main from "../components/main/Main";
import SignUp from "../components/signup/SignUp";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";

function Home() {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const utmSource = params.get("utm_source");
    const utmMedium = params.get("utm_medium");
    const utmCampaign = params.get("utm_campaign");

    if (utmSource && utmMedium && utmCampaign) {
      ReactGA.event({
        category: "Campaign",
        action: "View",
        label: `${utmSource} | ${utmMedium} | ${utmCampaign}`,
      });
    }
  }, [location]);
  return (
    <div className="w-full pb-10 text-[#2D2D2D]">
      <Header />
      <div className="mt-32 px-[10%]">
        <Main />
        <div id="features" className="my-8 text-4xl text-center font-poppins">
          Features
        </div>
        <Feature />
        <div
          id="get-started"
          className="mb-8 mt-24 text-4xl text-center font-poppins"
        >
          Get Started
        </div>
        <SignUp />
        <div className="mt-10"></div>
        <ContactUs />
        <div className="mt-10"></div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
