export const languageOptions = [
  { value: "English", label: "English" },
  { value: "Tamil", label: "Tamil" },
  { value: "Thai", label: "Thai" },
  { value: "Berber", label: "Berber" },
  { value: "Hassaniya", label: "Hassaniya" },
  { value: "Spanish", label: "Spanish" },
  { value: "Chinese", label: "Chinese" },
  { value: "French", label: "French" },
  { value: "Palauan", label: "Palauan" },
  { value: "German", label: "German" },
  { value: "Russian", label: "Russian" },
  { value: "Uzbek", label: "Uzbek" },
  { value: "Aymara", label: "Aymara" },
  { value: "Guarani", label: "Guarani" },
  { value: "Quechua", label: "Quechua" },
  { value: "Mongolian", label: "Mongolian" },
  { value: "Malay", label: "Malay" },
  { value: "Tamil", label: "Tamil" },
  { value: "Seychellois Creole", label: "Seychellois Creole" },
  { value: "Norwegian", label: "Norwegian" },
  { value: "Haitian Creole", label: "Haitian Creole" },
  { value: "Nauru", label: "Nauru" },
  { value: "Hiri Motu", label: "Hiri Motu" },
  { value: "Tok Pisin", label: "Tok Pisin" },
  { value: "Portuguese", label: "Portuguese" },
  { value: "Urdu", label: "Urdu" },
  { value: "Swahili", label: "Swahili" },
  { value: "Tswana", label: "Tswana" },
  { value: "Italian", label: "Italian" },
  { value: "Arabic", label: "Arabic" },
  { value: "Chamorro", label: "Chamorro" },
  { value: "Belarusian", label: "Belarusian" },
  { value: "Icelandic", label: "Icelandic" },
  { value: "Hebrew", label: "Hebrew" },
  { value: "Maltese", label: "Maltese" },
  { value: "Macedonian", label: "Macedonian" },
  { value: "Kikongo", label: "Kikongo" },
  { value: "Lingala", label: "Lingala" },
  { value: "Tshiluba", label: "Tshiluba" },
  { value: "Gilbertese", label: "Gilbertese" },
  { value: "Swazi", label: "Swazi" },
  { value: "Greek", label: "Greek" },
  { value: "Turkish", label: "Turkish" },
  { value: "Dzongkha", label: "Dzongkha" },
  { value: "Indonesian", label: "Indonesian" },
  { value: "Albanian", label: "Albanian" },
  { value: "Serbian", label: "Serbian" },
  { value: "Korean", label: "Korean" },
  { value: "Aramaic", label: "Aramaic" },
  { value: "Sorani", label: "Sorani" },
  { value: "Thai", label: "Thai" },
  { value: "Chibarwe", label: "Chibarwe" },
  { value: "Kalanga", label: "Kalanga" },
  { value: "Khoisan", label: "Khoisan" },
  { value: "Ndau", label: "Ndau" },
  { value: "Northern Ndebele", label: "Northern Ndebele" },
  { value: "Chewa", label: "Chewa" },
  { value: "Shona", label: "Shona" },
  { value: "Sotho", label: "Sotho" },
  { value: "Tonga", label: "Tonga" },
  { value: "Tsonga", label: "Tsonga" },
  { value: "Venda", label: "Venda" },
  { value: "Xhosa", label: "Xhosa" },
  { value: "Zimbabwean Sign Language", label: "Zimbabwean Sign Language" },
  { value: "Samoan", label: "Samoan" },
  { value: "German", label: "German" },
  { value: "Bulgarian", label: "Bulgarian" },
  { value: "Amharic", label: "Amharic" },
  { value: "Romanian", label: "Romanian" },
  { value: "Malagasy", label: "Malagasy" },
  { value: "Bislama", label: "Bislama" },
  { value: "Dutch", label: "Dutch" },
  { value: "Papiamento", label: "Papiamento" },
  {
    value: "New Zealand Sign Language",
    label: "New Zealand Sign Language",
  },
  { value: "Nepali", label: "Nepali" },
  { value: "Swedish", label: "Swedish" },
  { value: "Carolinian", label: "Carolinian" },
  { value: "Danish", label: "Danish" },
  { value: "Faroese", label: "Faroese" },
  { value: "Filipino", label: "Filipino" },
  { value: "Persian (Farsi)", label: "Persian (Farsi)" },
  { value: "Maldivian", label: "Maldivian" },
  { value: "Norfuk", label: "Norfuk" },
  { value: "Estonian", label: "Estonian" },
  { value: "Turkmen", label: "Turkmen" },
  { value: "Burmese", label: "Burmese" },
  { value: "Latvian", label: "Latvian" },
  { value: "Bosnian", label: "Bosnian" },
  { value: "Croatian", label: "Croatian" },
  { value: "Manx", label: "Manx" },
  { value: "Irish", label: "Irish" },
  { value: "Kyrgyz", label: "Kyrgyz" },
  { value: "Kinyarwanda", label: "Kinyarwanda" },
  { value: "Tajik", label: "Tajik" },
  { value: "Niuean", label: "Niuean" },
  { value: "Azerbaijani", label: "Azerbaijani" },
  { value: "Ukrainian", label: "Ukrainian" },
  { value: "Tetum", label: "Tetum" },
  { value: "Belizean Creole", label: "Belizean Creole" },
  { value: "Lao", label: "Lao" },
  { value: "Armenian", label: "Armenian" },
  { value: "Japanese", label: "Japanese" },
  { value: "Dari", label: "Dari" },
  { value: "Pashto", label: "Pashto" },
  { value: "Bengali", label: "Bengali" },
  { value: "Vietnamese", label: "Vietnamese" },
  { value: "Marshallese", label: "Marshallese" },
  { value: "Tigrinya", label: "Tigrinya" },
  { value: "Khmer", label: "Khmer" },
  { value: "Upper Guinea Creole", label: "Upper Guinea Creole" },
  { value: "Greenlandic", label: "Greenlandic" },
  { value: "Norwegian Nynorsk", label: "Norwegian Nynorsk" },
  { value: "Norwegian Bokm\u00e5l", label: "Norwegian Bokm\u00e5l" },
  { value: "Sami", label: "Sami" },
  { value: "Kazakh", label: "Kazakh" },
  { value: "Tokelauan", label: "Tokelauan" },
  { value: "Mauritian Creole", label: "Mauritian Creole" },
  { value: "Polish", label: "Polish" },
  { value: "Fijian", label: "Fijian" },
  { value: "Fiji Hindi", label: "Fiji Hindi" },
  { value: "Slovak", label: "Slovak" },
  { value: "Slovene", label: "Slovene" },
  { value: "Somali", label: "Somali" },
  { value: "Tongan", label: "Tongan" },
  { value: "Finnish", label: "Finnish" },
  { value: "Sango", label: "Sango" },
  { value: "Tuvaluan", label: "Tuvaluan" },
  { value: "Kirundi", label: "Kirundi" },
  { value: "Jamaican Patois", label: "Jamaican Patois" },
  { value: "Latin", label: "Latin" },
  { value: "Lithuanian", label: "Lithuanian" },
  { value: "Hindi", label: "Hindi" },
  { value: "Swiss German", label: "Swiss German" },
  { value: "Romansh", label: "Romansh" },
  { value: "Catalan", label: "Catalan" },
  { value: "Afrikaans", label: "Afrikaans" },
  { value: "Herero", label: "Herero" },
  { value: "Khoekhoe", label: "Khoekhoe" },
  { value: "Kwangali", label: "Kwangali" },
  { value: "Lozi", label: "Lozi" },
  { value: "Ndonga", label: "Ndonga" },
  { value: "Luxembourgish", label: "Luxembourgish" },
  { value: "Comorian", label: "Comorian" },
  { value: "Georgian", label: "Georgian" },
  { value: "Hungarian", label: "Hungarian" },
  { value: "Czech", label: "Czech" },
  { value: "Montenegrin", label: "Montenegrin" },
  { value: "Sinhala", label: "Sinhala" },
  { value: "Southern Ndebele", label: "Southern Ndebele" },
  { value: "Northern Sotho", label: "Northern Sotho" },
  { value: "Zulu", label: "Zulu" },
];

export const amtINRMonthly = [
  {
    value: 0,
    label: "₹100",
    amount: "100",
  },
  {
    value: 20,
    label: "₹150",
    amount: "150",
  },
  {
    value: 40,
    label: "₹200",
    amount: "200",
  },
  {
    value: 60,
    label: "₹250",
    amount: "250",
  },
  {
    value: 80,
    label: "₹350",
    amount: "350",
  },
  {
    value: 100,
    label: "₹500",
    amount: "500",
  },
];

export const amtINRYearly = [
  {
    value: 0,
    label: "₹600",
    amount: "600",
  },
  {
    value: 20,
    label: "₹800",
    amount: "800",
  },
  {
    value: 40,
    label: "₹900",
    amount: "900",
  },
  {
    value: 60,
    label: "₹1000",
    amount: "1000",
  },
  {
    value: 80,
    label: "₹1100",
    amount: "1100",
  },
  {
    value: 100,
    label: "₹1200",
    amount: "1200",
  },
];

export const amtUSDMonthly = [
  {
    value: 0,
    label: "$5",
    amount: "5",
  },
  {
    value: 20,
    label: "$10",
    amount: "10",
  },
  {
    value: 40,
    label: "$12",
    amount: "12",
  },
  {
    value: 60,
    label: "$16",
    amount: "16",
  },
  {
    value: 80,
    label: "$20",
    amount: "20",
  },
  {
    value: 100,
    label: "$24",
    amount: "24",
  },
];

export const amtUSDYearly = [
  {
    value: 0,
    label: "$25",
    amount: "25",
  },
  {
    value: 20,
    label: "$29",
    amount: "29",
  },
  {
    value: 40,
    label: "$34",
    amount: "34",
  },
  {
    value: 60,
    label: "$39",
    amount: "39",
  },
  {
    value: 80,
    label: "$45",
    amount: "45",
  },
  {
    value: 100,
    label: "$50",
    amount: "50",
  },
];
export const states = [
  { value: "Andhra Pradesh", label: "Andhra Pradesh" },
  { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
  { value: "Assam", label: "Assam" },
  { value: "Bihar", label: "Bihar" },
  { value: "Chhattisgarh", label: "Chhattisgarh" },
  { value: "Goa", label: "Goa" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Haryana", label: "Haryana" },
  { value: "Himachal Pradesh", label: "Himachal Pradesh" },
  { value: "Jharkhand", label: "Jharkhand" },
  { value: "Karnataka", label: "Karnataka" },
  { value: "Kerala", label: "Kerala" },
  { value: "Madhya Pradesh", label: "Madhya Pradesh" },
  { value: "Maharashtra", label: "Maharashtra" },
  { value: "Manipur", label: "Manipur" },
  { value: "Meghalaya", label: "Meghalaya" },
  { value: "Mizoram", label: "Mizoram" },
  { value: "Nagaland", label: "Nagaland" },
  { value: "Odisha", label: "Odisha" },
  { value: "Punjab", label: "Punjab" },
  { value: "Rajasthan", label: "Rajasthan" },
  { value: "Sikkim", label: "Sikkim" },
  { value: "Tamil Nadu", label: "Tamil Nadu" },
  { value: "Telangana", label: "Telangana" },
  { value: "Tripura", label: "Tripura" },
  { value: "Uttarakhand", label: "Uttarakhand" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  { value: "West Bengal", label: "West Bengal" },
];

export function valuetextINR(value) {
  return `₹${value}`;
}

export function valuetextUSD(value) {
  return `$${value}`;
}
